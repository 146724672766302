<template>
    <v-container>
        <base-material-card icon="mdi-currency-usd" title="Listas" class="px-5 py-3">
            <v-row>
                <v-col cols="12">
                    <v-btn color="primary" @click="$router.push({name: 'ListaCreate'})">Nuevo</v-btn>
                    <v-text-field v-model="pagination.search" append-icon="mdi-magnify" label="Búsqueda" single-line hide-details @keyup="pagination.currentPage = 1; loadListas()"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-data-table :headers="headers" :items="listas" item-key="id" hide-default-footer disable-pagination class="elevation-1">
                        <template v-slot:item.accciones="{item}">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn fab icon x-small v-on="on" @click="$router.push({name: 'ListaEdit',params: {id: item.id}})">
                                        <v-icon>far fa-edit</v-icon>
                                    </v-btn>
                                </template>
                                <span>Editar</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn fab icon x-small v-on="on" @click="downloadListaExcel(item)">
                                        <v-icon>far fa-file-excel</v-icon>
                                    </v-btn>
                                </template>
                                <span>Precios</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-col>
                <v-col col="12">
                    <v-pagination v-model="pagination.currentPage" class="my-4" :length="pagination.lastPage" @input="loadListas" :total-visible="10" color="primary"></v-pagination>
                </v-col>
            </v-row>
        </base-material-card>
    </v-container>
</template>
<script>
    export default {
        data: () => ({
            headers: [
                { text: 'Nombre', value: 'nombre', sortable: false },
                { text: 'Código', value: 'codigo', sortable: false },
                { text: 'Acciones', value: 'accciones', sortable: false },
            ],
            listas: [],
            pagination: {
                perPage: 10,
                lastPage: 1,
                currentPage: 1,
                search: null
            }
        }),
        mounted() {
            this.loadListas()
        },
        methods: {
            loadListas() {
                this.$http.get('/listas/', {
                    params: {
                        page: this.pagination.currentPage,
                        search: this.pagination.search,
                    }
                }).then(response => {
                    this.listas = response.data.data
                    this.pagination.lastPage = response.data.last_page
                    this.pagination.currentPage = response.data.current_page
                })
            },
            downloadListaExcel(lista) {
                window.location.assign(`/listas/${lista.id}/precios/excel`)
            }
        }
    }
</script>